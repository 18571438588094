import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import { Container, Row, Col } from "react-bootstrap";
// Images
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo";

const BikeRacks = () => {
  return (
    <Layout>
      <Seo
        title="Range Vélos - BIKESAFE"
        description="Notre gamme de ranges vélos offre différentes capacités de rangement, de 1 à 24 vélos sur un seul rack ! Tous les râteliers vélos que nous proposons sont résistants à la rouille, et défient les intempéries comme les malfaiteurs."
      />
      <Container>
        <Row>
          <Col sm="12">
            <h1 className="mt-5 mb-5 text-center uppercase">
              Range Vélos
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <p className="flow-text">
              Notre gamme de ranges vélos offre différentes capacités de rangement, de 1 à 24 vélos sur un seul rack ! Tous les râteliers vélos que nous proposons sont résistants à la rouille, et défient les intempéries comme les malfaiteurs.
            </p>
            <p className="flow-text">Découvrez ci-dessous notre gamme de premier choix de râteliers vélos pour répondre à vos besoins en matière de sécurité :</p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <hr />
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/61">
              <StaticImage placeholder="blurred" className="fluid" src="../product/61/homepage-61.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Range Vélos - 2 niveaux</h3>
              <p>Le range vélos à deux niveaux est un système très innovant et pratique qui permet d'accueillir deux fois plus de vélos que tous les autres range vélos standards.</p>
              <p className="shop-now">Obtenir Un Devis</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/230">
              <StaticImage placeholder="blurred" className="fluid" src="../product/230/homepage-230.jpg"></StaticImage>
              <h3 className="uppercase mt-3">BikeSafe - Range Vélos - 2 niveaux</h3>
              <p>Le porte-vélos à deux niveaux BikeSafe est un système de stationnement pour vélos pionnier et convivial, avec un excellent stockage de vélos peu encombrant...</p>
              <p className="shop-now">Obtenir Un Devis</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/80">
              <StaticImage placeholder="blurred" className="fluid" src="../product/80/homepage-80.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Range Vélos autonome, 1 ou 2 côtés</h3>
              <p>Le range vélos autonome permet aux utilisateurs d'optimiser leur espace au sol. L'armature peut être boulonnée dans le sol à n'importe...</p>
              <p className="shop-now">Obtenir Un Devis</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/88">
              <StaticImage placeholder="blurred" className="fluid" src="../product/88/homepage-88.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Range Vélos semi-vertical</h3>
              <p>Pour optimiser l'utilisation d'un espace réduit. Les supports semi-verticaux peuvent être utilisés à l'intérieur comme à l'extérieur. La roue arrière peut être...</p>
              <p className="shop-now">Obtenir Un Devis</p>
            </Link>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default BikeRacks;